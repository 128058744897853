import { useState, useRef } from 'react'
import styles from './index.module.scss'

const StaticSeoTypeEnum = {
  1: { title: 'Pozisyona Göre İlanlar', url: 'pozisyona-gore-ilanlar' },
  2: { title: 'Sektöre Göre İlanlar', url: 'sektore-gore-ilanlar' },
  3: { title: 'İllere Göre İlanlar', url: 'illere-gore-ilanlar' },
  4: { title: 'İlçelere Göre İlanlar', url: 'ilcelere-gore-ilanlar' },
}

const SeoLinks = (props) => {
  const { items } = props
  const [collapseIndex, setCollapseIndex] = useState(0)

  const onCollapse = (e, index) => {
    const targetIndex = index == collapseIndex ? -1 : index
    setCollapseIndex(targetIndex)

    setTimeout(() => {
      const el = refs.current[targetIndex]
      if (el && targetIndex >= 0) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    })
  }

  const refs = useRef([])
  const setRef = (index) => (element) => {
    refs.current[index] = element
  }

  return (
    <section data-test="home-seo-links" className={styles.homeSeoLinks}>
      <div className="container">
        {items?.map((item, index) => {
          const seoType = StaticSeoTypeEnum[item?.staticPositionType]

          return (
            <div
              key={index}
              ref={setRef(index)}
              className={`${styles.item} ${
                collapseIndex == index ? styles.collapse : ''
              }`}
            >
              <div
                className={styles.head}
                onClick={(e) => onCollapse(e, index)}
              >
                <h2>{seoType?.title}</h2>
                <div className={styles.control}>
                  <a
                    onClick={(e) => e.stopPropagation()}
                    href={`${process.env.NEXT_PUBLIC_MAIN_URL2}/${seoType?.url}`}
                  >
                    Tümünü Gör
                  </a>
                  <i className="icon icon-angle-down"></i>
                </div>
              </div>
              <div className={styles.content}>
                <ul>
                  {item?.getStaticPositionDetails?.map((link, linkIndex) => {
                    return (
                      <li key={linkIndex}>
                        <a
                          title={link?.anchorText}
                          href={`${process.env.NEXT_PUBLIC_MAIN_URL2}/is-ilanlari${link?.url}`}
                        >
                          <span>{link?.anchorText}</span>
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default SeoLinks
