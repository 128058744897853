import { useEffect, useState } from 'react'
const useDebounce = (value, delay = 300) => {
  const [debouncedValue, setDebouncedValue] = useState('')

  useEffect(() => {
    const timeout = setTimeout(() => setDebouncedValue(value), delay)
    return () => clearTimeout(timeout)
  }, [value])

  return debouncedValue
}

export default useDebounce
