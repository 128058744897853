export const referencesCompanyArr = [
  {
    id: 1,
    imgUrl: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/References/Company/amazon.png`,
    alt: 'Amazon',
  },
  {
    id: 2,
    imgUrl: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/References/Company/mado.png`,
    alt: 'Mado',
  },
  {
    id: 3,
    imgUrl: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/References/Company/starbucks.png`,
    alt: 'Starbucks',
  },
  {
    id: 4,
    imgUrl: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/References/Company/general-mobile.png`,
    alt: 'General Mobile',
  },
  {
    id: 5,
    imgUrl: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/References/Company/burger-king.png`,
    alt: 'Burger King',
  },
  {
    id: 6,
    imgUrl: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/References/Company/yurtici.png`,
    alt: 'Yurtiçi Kargo',
  },
  {
    id: 7,
    imgUrl: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/References/Company/mng.png`,
    alt: 'Mng Kargo',
  },
  {
    id: 8,
    imgUrl: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/References/Company/dominos.png`,
    alt: 'Dominos',
  },
  {
    id: 9,
    imgUrl: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/References/Company/a101.png`,
    alt: 'A-101',
  },
  {
    id: 10,
    imgUrl: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/References/Company/mcdonalds.png`,
    alt: 'Mc Donalds',
  },
  {
    id: 11,
    imgUrl: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/References/Company/marmaris.png`,
    alt: 'Marmaris Büfe',
  },
  {
    id: 12,
    imgUrl: `${process.env.NEXT_PUBLIC_SERVICE_IMAGE_URL}/References/Company/boyner.png`,
    alt: 'Boyner',
  },
]
