import QRCode from 'react-qr-code'
import styles from './index.module.scss'
import { ga4EventButtonClick } from '../../../utils/general'

const EmployerInfo = () => {
  return (
    <section className={styles.employerSection}>
      <div className={`${styles.main} container`}>
        <div className={styles.rowBox}>
          <div className="col-lg-7">
            <div className={styles.info}>
              <h2>Çalışan mı Arıyorsun?</h2>
              <div className={styles.desc}>
                Yeni işini arayan binlerce kişiye ulaşmak için hemen İşin Olsun
                uygulamasından işveren hesabını oluştur, ücretsiz ilan ver!
              </div>
              <div className={`${styles.actions} d-lg-flex d-none`}>
                <a href={`${process.env.NEXT_PUBLIC_MAIN_URL2}/isveren`}>
                  <button
                    onClick={() => ga4EventButtonClick('calisan-ariyorum')}
                    type="button"
                    className="btn"
                  >
                    Çalışan Arıyorum
                  </button>
                </a>

                <div className={styles.divider}></div>
                <div className={styles.qrArea}>
                  <div className={styles.text}>
                    QR kodu okut, <br />
                    <b>uygulamayı indir!</b>
                  </div>
                  <QRCode
                    className={styles.code}
                    value="https://app.adjust.com/g3r0yk5"
                    size={60}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className={`${styles.info} ${styles.right} `}>
              <a
                onClick={() => ga4EventButtonClick('calisan-ariyorum')}
                href={`${process.env.NEXT_PUBLIC_MAIN_URL2}/isveren`}
              >
                <span className={`${styles.redirect} d-lg-none d-flex`}>
                  Detayı Gör
                </span>
              </a>
              <img
                src={`${process.env.NEXT_PUBLIC_CDN_URL}/_next/static/images/employer-section.png`}
                srcSet={`${process.env.NEXT_PUBLIC_CDN_URL}/_next/static/images/employer-section@2x.png 2x`}
                loading="lazy"
              />
              <div className={`${styles.actions} d-lg-none d-flex`}>
                <a href="https://app.adjust.com/g3r0yk5">
                  <button type="button" className="btn">
                    Uygulamayı İndir
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default EmployerInfo
