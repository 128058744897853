import PropTypes from 'prop-types'

const HomeChips = ({ items, handleOnClick, selectedItem, version }) => {
  return (
    <>
      <div className={`chips-container ${version || ''}`}>
        {items?.map((item, index) => (
          <a
            key={index}
            onClick={() => handleOnClick(item)}
            href={`${process.env.NEXT_PUBLIC_MAIN_URL2}/is-ilanlari${item?.url}`}
            className={`${
              selectedItem?.id === item?.id ? 'chip active' : 'chip'
            } hrefLink`}
          >
            {item?.name}
          </a>
        ))}
      </div>
      <style jsx global>{`
        .chips-container {
          align-items: center;
          display: flex;
          gap: 12px 8px;
          justify-content: flex-start;
          margin-top: 16px;
          overflow-x: auto;
          padding-bottom: 1px;
          position: relative;
          white-space: nowrap;
          width: calc(100% + 14px);
          padding-right: 14px;

          .hrefLink {
            color: #333;
            text-decoration: none;
          }

          scrollbar-width: none; /* Firefox */
          -ms-overflow-style: none; /* IE 10+ */
          &::-webkit-scrollbar {
            display: none; /* Chrome/Safari/Webkit */
            width: 0;
          }

          &.v2 {
            &.chips-container {
              margin-top: 0;
              flex-flow: nowrap;
              .chip {
                height: 36px;
                border-radius: 18px;
                padding: 0 12px;
              }
            }

            .chip {
              &:hover {
                border-color: #d8d8d8;
                background-color: #f4f4f8;
                color: #333333;
              }
            }

            .active {
              border-color: #d8d8d8;
              background-color: #fff;
              color: #333333;
            }
          }

          .chip {
            align-items: center;
            background-color: #fff;
            border-radius: 12.5px;
            border: 1px solid #d8d8d8;
            cursor: pointer;
            display: flex;
            font-size: 14px;
            height: 25px;
            justify-content: center;
            padding: 0 8px;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &:hover {
              border-color: #003893;
              background-color: #003893;
              color: #fff;
            }

            &:active {
              transform: translateY(1px);
            }
          }

          .active {
            border-color: #003893;
            background-color: #003893;
            color: #fff;
          }

          @media (min-width: 992px) {
            margin-top: 24px;

            &.v2 {
              margin: 0 0 12px 0;
              &.chips-container {
                flex-flow: wrap;
                max-width: 570px;
              }
            }

            .chip {
              padding: 0 12px;
              height: 36px;
              border-radius: 18px;
            }

            &.small {
              margin-top: 4px;

              .chip {
                padding: 0 8px;
                height: 25px;
                font-size: 12px;
              }
            }
          }
        }
      `}</style>
    </>
  )
}

HomeChips.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleOnClick: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
  version: PropTypes.string,
}

HomeChips.defaultProps = {
  selectedItem: {},
}

export default HomeChips
