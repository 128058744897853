import { memo, useEffect, useState } from 'react'
import Head from 'next/head'
import dynamic from 'next/dynamic'

import services from '../../../services'
import { homeBannerChipItems } from 'models/popular'
import {
  checkCookies,
  getCookies,
  removeCookies,
  setCookies,
} from '../../../utils/cookies-next'
import {
  geoLocationProcess,
  locationMinLength,
  positionMinLength,
  redirectToJobListings,
  toUrl,
} from '../../../utils/general'
import useDebounce from '../../../utils/useDebounce'
import { useGlobalContext } from 'contexts'

import HomeChips from '@components/home-chips'
import HomeSearchV2 from '@components/ui/home-search-v2'

const LocationPermissionDialog = dynamic(() =>
  import('components/ui/locationpermissiondialog')
)

import styles from './index.module.scss'
import { useDebounceCallback } from 'utils/useDebounceCallback'
import { positionListSearch } from 'utils/mapping'

const HomeHeroBanner = memo(({ totaljobcount, totalcandidatecount }) => {
  const [positionList, setPositionList] = useState([])
  const [locationList, setLocationList] = useState([])
  const [searchLocation, setSearchLocation] = useState({})
  const [currentSearchLocation, setCurrentSearchLocation] = useState({})
  const [positionKeyword, setPositionKeyword] = useState()
  const [positionName, setPositionName] = useState()
  const [locationKeyword, setLocationKeyword] = useState()
  const [locationProcess, setLocationProcess] = useState(false)
  const [selectedItem, setSelectedItem] = useState()
  const [locationPermissionDialogOpen, setLocationPermissionDialogOpen] =
    useState(false)

  const debouncedLocationValue = useDebounce(locationKeyword)
  const heroImgUrl1x = `${process.env.NEXT_PUBLIC_CDN_URL}/_next/static/images/home-hero@1x.png`
  const heroImgUrl2x = `${process.env.NEXT_PUBLIC_CDN_URL}/_next/static/images/home-hero@2x.png`

  const { allPositionList, setOnAllPositionList } = useGlobalContext()

  useEffect(() => {
    if (checkCookies(null, 'BlueCollar_keyword'))
      setPositionKeyword(getCookies(null, 'BlueCollar_keyword'))

    if (checkCookies(null, 'BlueCollar_locationViewport')) {
      const res = JSON.parse(getCookies(null, 'BlueCollar_locationViewport'))
      setSearchLocation(res)
      setLocationKeyword(res?.lc)
    }
  }, [])

  const ga4Event = (type, loc) => {
    let city
    let district
    if (loc) {
      let location = loc.split(', ')
      district = toUrl(location[location.length - 2])
      city = toUrl(location[location.length - 1])
    }

    dataLayer.push({
      event: 'ga4_event',
      event_name: 'search_criteria',
      event_parameters: {
        ...(city ? { city } : {}),
        ...(district ? { district } : {}),
        content_type: type,
      },
    })
  }
  const onLocationPermissionDialogOpen = (directOpen) => {
    if (locationPermissionDialogOpen) return
    if (directOpen || !checkCookies(null, 'BlueCollar_UserLocationDisabled')) {
      setLocationPermissionDialogOpen(true)
      setCookies(null, 'BlueCollar_UserLocationDisabled', true, {
        expires: 30,
      })
    }
  }

  const locationPermissionDialogClose = () => {
    setLocationPermissionDialogOpen(false)
  }
  const onPullCurrentLocation = async () => {
    ga4Event('su-anki-konum')
    setLocationProcess(true)
    const geo = await geoLocationProcess()

    if (geo?.location) {
      setLocationKeyword(geo?.location?.address)
      setCurrentSearchLocation({ useCurrentLocation: true })
      setSearchLocation({})
    } else {
      onLocationPermissionDialogOpen(true)
    }

    setLocationProcess(false)
  }

  const onPositionChange = (e, isSelect) => {
    if (allPositionList?.length && e?.length >= positionMinLength)
      getPositionSearch(e)
    else if (!allPositionList?.length) setOnAllPositionList()

    if (!e?.length) {
      setPositionList([])
    }

    setPositionKeyword(e)

    if (isSelect) setPositionName(e)
    else setPositionName()
  }

  const getPositionSearch = useDebounceCallback((query) => {
    const positionJobType = undefined

    const result = positionListSearch(
      allPositionList,
      query,
      positionJobType,
      positionMinLength
    )

    setPositionList(result || [])
    // if (!result) setPositionEmptyLabel('Araman için sonuç bulunamadı.')
  }, 300) //debounce süresi

  useEffect(() => {
    if (debouncedLocationValue?.length >= locationMinLength)
      getLocation(debouncedLocationValue)
  }, [debouncedLocationValue])

  const getLocation = (e) => {
    const params = {
      keyword: e,
    }
    try {
      services
        .wrapper(null, services.configs.places.suggests.get, params)
        .then((resp) => {
          if (resp?.data?.result) setLocationList(resp.data.result)
        })
    } catch (error) {
      //
    }
  }

  const onLocationChange = async (e, isSelect) => {
    setLocationKeyword(e)

    if (!isSelect && e?.length >= locationMinLength) {
      setSearchLocation({ lc: e })
    }

    if (!e?.length > 0) {
      setLocationList([])
      setSearchLocation({})
    }

    if (isSelect && e?.length > 0) {
      const params = {
        address: e,
      }

      let resp
      try {
        resp = await services.wrapper(
          null,
          services.configs.places.details.get,
          params
        )
      } catch (error) {
        //
      }

      if (resp?.data?.result) {
        const city = resp.data.result?.city?.toString()
        const town = resp.data.result?.townName?.toString()

        setSearchLocation({
          lc: resp.data.result?.name,
          ...(city && { city }),
          ...(town && { town }),
          brlat: resp.data.result?.viewportBottomRightLatitude?.toString(),
          brlon: resp.data.result?.viewportBottomRightLongitude?.toString(),
          tplat: resp.data.result?.viewportTopLeftLatitude?.toString(),
          tplon: resp.data.result?.viewportTopLeftLongitude?.toString(),
        })
      }
    }
  }

  const ga4EventWidgetClick = (label) => {
    dataLayer.push({
      event: 'ga4_event',
      event_name: 'widget_click',
      event_parameters: {
        widget: 'populer-aramalar',
        label: toUrl(label) || undefined,
      },
    })
  }
  const onSelectChip = (item) => {
    setSelectedItem(item)
    const { type, name } = item

    if (type === 'job') {
      setPositionKeyword(name)
      ga4EventWidgetClick(name)
    } else {
      setLocationKeyword(name)
      setSearchLocation({ lc: name })
    }
  }

  const searchFormSubmit = () => {
    const searchPosition = {}

    if (positionKeyword) {
      searchPosition.kw = positionKeyword
      if (positionName) searchPosition.pos = positionName

      setCookies(null, 'BlueCollar_keyword', searchPosition.kw, {
        expires: 365,
      })
    } else removeCookies(null, 'BlueCollar_keyword')

    if (searchLocation?.lc) {
      setCookies(null, 'BlueCollar_locationViewport', searchLocation, {
        expires: 365,
      })
    } else removeCookies(null, 'BlueCollar_locationViewport')

    const searchParameters = {
      ...searchPosition,
      ...searchLocation,
      ...currentSearchLocation,
    }

    //Redirect
    redirectToJobListings(searchParameters)
  }

  return (
    <>
      <Head>
        <link
          rel="preload"
          as="image"
          imageSrcSet={`${heroImgUrl1x} 1x, ${heroImgUrl2x} 2x`}
        />
      </Head>
      <div className={styles.root}>
        <div className="container">
          <div className={`${styles.main} row`}>
            <div className="col-lg-7">
              <div className={styles.homeHeroContent}>
                <div className={styles.sloganTitle}>
                  <h1>
                    <span className={styles.bold}>Uzaklarda Arama,</span> <br />
                    Evine Yakın İşleri Kolayca Bul!
                  </h1>
                </div>

                <div className={styles.mobileImgWrapper}>
                  <img
                    className={styles.homeMobileImage}
                    src={heroImgUrl1x}
                    srcSet={`${heroImgUrl2x} 2x`}
                    alt="İşin Olsun Ana Sayfa"
                  />
                  <div className={styles.mobileEmployeesInfo}>
                    <span className={styles.count}>{totalcandidatecount}</span>{' '}
                    İş Arayan
                  </div>
                  <div className={styles.mobileEmployerInfo}>
                    <span className={styles.count}>{totaljobcount} </span>
                    İş İlanı
                  </div>
                </div>

                <HomeSearchV2
                  onFormSubmit={searchFormSubmit}
                  onPositionChange={onPositionChange}
                  onLocationChange={onLocationChange}
                  onPullCurrentLocation={onPullCurrentLocation}
                  positionList={positionList}
                  locationList={locationList}
                  positionKeyword={positionKeyword}
                  locationKeyword={locationKeyword}
                  locationProcess={locationProcess}
                />
                <HomeChips
                  handleOnClick={onSelectChip}
                  items={homeBannerChipItems}
                  selectedItem={selectedItem}
                  version="v2"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className={styles.desktopImgWrapper}>
                <img
                  className={styles.homeDesktopImage}
                  src={heroImgUrl1x}
                  srcSet={`${heroImgUrl2x} 2x`}
                  alt="İşin Olsun Ana Sayfa"
                />
                <div className={styles.desktopEmployeesInfo}>
                  <span className={styles.count}>{totalcandidatecount}</span> İş
                  Arayan
                </div>
                <div className={styles.desktopEmployerInfo}>
                  <span className={styles.count}>{totaljobcount} </span>İş İlanı
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {locationPermissionDialogOpen && (
        <LocationPermissionDialog
          onClose={locationPermissionDialogClose}
          open={locationPermissionDialogOpen}
        />
      )}
    </>
  )
})

export default HomeHeroBanner
