import Skeleton from 'react-loading-skeleton'
import styles from './index.module.scss'
import 'react-loading-skeleton/dist/skeleton.css'

const HomeJobCardSkeleton = ({ amount = 9 }) => {
  const loadCards = Array(amount).fill(1)
  return (
    <div className={styles.root}>
      {loadCards.map((_, i) => (
        <div className={styles.card} key={i}>
          <Skeleton borderRadius={5} width={60} height={60} />
          <div className={styles.line}>
            <Skeleton count={3} height={13} />
          </div>
        </div>
      ))}
    </div>
  )
}

export default HomeJobCardSkeleton
