import { normalizeString } from './stringOperation'

export const positionListSearch = (list, query, positionJobType) => {
  const qe = normalizeString(query)

  const maxResults = 20
  const startsWithResults = []
  const includesResults = []

  list.forEach((item) => {
    if (positionJobType && item.positionJobType !== positionJobType) {
      return
    }
    if (
      startsWithResults.length < maxResults &&
      item.normalizedName.startsWith(qe)
    ) {
      startsWithResults.push(item)
    } else if (
      includesResults.length < maxResults &&
      item.normalizedName.includes(qe)
    ) {
      includesResults.push(item)
    }
  })

  const finalResults = startsWithResults.concat(
    includesResults.slice(0, maxResults - startsWithResults.length)
  )

  return finalResults
}
