import { useState, useEffect } from 'react'
import { isAvailableSessionStorage } from 'utils/general'
import styles from './index.module.scss'

const AppRedirect = () => {
  const [closing, setClosing] = useState(false)
  const [showAppRedirect, setShowAppRedirect] = useState()

  useEffect(() => {
    setShowAppRedirect(
      !(isAvailableSessionStorage() && sessionStorage.getItem('appRedirect'))
    )
    setClosing(!showAppRedirect)
  }, [showAppRedirect])

  const closeBottomSheet = () => {
    if (isAvailableSessionStorage()) {
      sessionStorage.setItem('appRedirect', true)
    }
    setClosing(true)
  }

  const handleTransitionEnd = () => {
    if (closing) {
      setShowAppRedirect(false)
    }
  }

  return (
    <div
      data-test="app-redirect"
      className={`${showAppRedirect ? 'd-lg-none' : 'd-none'}`}
    >
      <div
        onClick={closeBottomSheet}
        className={styles['bottom-sheet-overlay']}
        data-test="bottom-sheet-overlay"
      ></div>
      <div
        className={`${styles['app-redirect']} ${
          closing ? styles['closed'] : ''
        }`}
        onTransitionEnd={handleTransitionEnd}
      >
        <div
          onClick={closeBottomSheet}
          className={styles['close']}
          data-test="close-button"
        >
          &times;
        </div>
        <div className={styles['content']}>
          <img
            src={`${process.env.NEXT_PUBLIC_CDN_URL}/_next/static/images/touch-icon-mobile.png`}
            alt="İşin Olsun"
          />
          <div className={styles['slogan']}>
            Uygulamada daha fazlasını keşfet!
          </div>
          <a href="https://app.adjust.com/g3r0yk5">
            <button type="button" className="btn">
              Hemen İndir
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default AppRedirect
