import { memo } from 'react'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import styles from './index.module.scss'

const WikiContent = memo((props) => {
  const { wikiContent } = props

  return (
    <>
      <div data-test="wiki-content" className={styles['wiki-content-desc-box']}>
        <div className="container">
          {wikiContent?.Text && (
            <div className={styles.title} data-test="title">
              {wikiContent.Text}
            </div>
          )}

          {wikiContent?.WikiboxUrlItems && (
            <div data-test="content" className={styles.content}>
              {wikiContent.WikiboxUrlItems?.map((item, index) => (
                <Fragment key={index}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={item.Url}
                    data-test="link"
                  >
                    {item.Title}
                  </a>
                  {index < wikiContent.WikiboxUrlItems?.length - 1 && ', '}
                </Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  )
})

WikiContent.propTypes = {
  wikiContent: PropTypes.object,
}

WikiContent.defaultProps = {
  wikiContent: {},
}

export default WikiContent
