import { memo, useEffect, useRef, useState } from 'react'

import services from '../../../services'

import {
  addResizePath,
  addSearchId,
  generateSearchId,
  getJobType,
  locationIdCheck,
  searchIdFeature,
  toUrl,
} from '../../../utils/general'
import { useGlobalContext } from '../../../contexts'
import classNames from 'classnames'

import styles from './index.module.scss'

const leftBadgeArr = ['Acil İlan']
const rightBadgeArr = ['Yeni', 'Son Gün']

const JobCardBasic2 = memo((props) => {
  const {
    order,
    jobId,
    companyName,
    positionName,
    townName,
    cityName,
    // description,
    // imageUrl,
    // largeImageUrl,
    shortAddress,
    // applicationType,
    // jobType,
    distance,
    // durationDay,
    durationDayText,
    shareUrl,
    workType,
    // descType,
    evaluationScore,
    companyVerificationStateType,
    tagDescription,
    // tagText,
    tagColor,
    isMultiApplication,
    hasFavorite,
    hiddenImage = false,
    hasJobType = true,
    hasSingleLine,
    fullBorder,
    mobileBorderTopAndBottomOnly,
    disableFavoriteCallback,
    searchId,
    pageType,
  } = props
  const imageUrl = addResizePath(props?.imageUrl)

  const [isJobFavorite, setIsJobFavorite] = useState(props?.isJobFavorite)
  const [callBackFuncName, setCallBackFuncName] = useState(null)
  const [hasBeenVisible, setHasBeenVisible] = useState(false) // JobCard'ın görünüp görünmediğini takip eder.

  const { isLogin, loginCallBack, setLoginCallBack, gtmLoaded } =
    useGlobalContext()

  const cardRef = useRef()

  const onJobSelectEvent = () => {
    if (isMultiApplication) return

    sendJobListItemEvent('select_item')
    const loadSearchId = {
      itemId: jobId,
      searchId: searchId?.searchId,
      listName: pageType,
      itemIndex: order,
      type: 2,
    }
    addSearchId(loadSearchId)
  }

  const onHandleOtherClick = (e) => {
    if (isMultiApplication) return

    const types = ['contextmenu', 'auxclick']
    if (types.includes(e?.type) && searchId?.searchId) {
      const searchIdData = {
        itemId: jobId,
        searchId: searchId.searchId,
        listName: pageType,
        itemIndex: order,
        type: 2,
      }

      addSearchId(searchIdData)
    }
  }

  const sendJobListItemEvent = (eventName = 'view_item_list') => {
    if (!searchIdFeature) return

    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: eventName,
      ecommerce: {
        items: [
          {
            search_id: searchId?.searchId,
            item_id: jobId || undefined,
            item_name: toUrl(positionName) || undefined,
            item_list_name: pageType,
            item_variant: workType === 4 ? 'hizmet' : 'ilan',
            location_id: locationIdCheck({ cityName, townName }),
            quantity: 1,
            index: order,
          },
        ],
      },
    })
  }

  useEffect(() => {
    if ('IntersectionObserver' in window && gtmLoaded) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0]?.isIntersecting && !hasBeenVisible) {
            sendJobListItemEvent()
            setHasBeenVisible(true)
          }
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0.1,
        }
      )

      setTimeout(() => {
        if (cardRef?.current) observer?.observe(cardRef.current)
      }, 0)

      return () => {
        if (cardRef?.current) observer?.unobserve(cardRef.current)
      }
    }
  }, [hasBeenVisible, gtmLoaded, jobId, order])

  const saveClickSearchIdEvent = () => {
    if (!searchIdFeature) return

    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: 'purchase',
      ecommerce: {
        currency: 'TRY',
        transaction_id: generateSearchId(),
        value: '0.00',
        items: [
          {
            search_id: searchId?.searchId,
            item_id: jobId || undefined,
            item_name: toUrl(positionName) || undefined,
            item_list_name: pageType,
            item_variant: workType === 4 ? 'hizmet' : 'ilan',
            location_id: locationIdCheck({ cityName, townName }),
            quantity: 1,
            index: order,
            affiliation: 'kaydet',
          },
        ],
      },
    })
  }

  const onJobFavorite = async () => {
    if (!isLogin) {
      setCallBackFuncName('onJobFavorite')
      props.onOpenActivationDialog('İlanı kaydetmek için')
      return
    }

    let resp

    try {
      resp = await services.wrapper(
        null,
        services.configs.favorites[isJobFavorite ? 'delete' : 'post'],
        {
          jobId,
        }
      )

      if (resp?.data?.result) {
        setIsJobFavorite(!isJobFavorite)
        if (!isJobFavorite) saveClickSearchIdEvent()
      }
    } catch (err) {
      const code = parseInt(err?.response?.status)
      if (
        code === 400 &&
        err?.response?.data?.errors?.[0]?.errorCode === '1126'
      ) {
        if (!isJobFavorite) {
          saveClickSearchIdEvent()
          setIsJobFavorite(true)
        }
      }
    }
  }

  useEffect(() => {
    if (!loginCallBack) return

    if (isLogin && callBackFuncName === 'onJobFavorite') {
      if (disableFavoriteCallback) {
        onJobSelectEvent()
      } else {
        onJobFavorite()
      }

      setLoginCallBack(false)
    }
  }, [loginCallBack])

  const onFavoriteClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onJobFavorite()
  }

  const cardClass = classNames({
    [styles['card']]: true,
    [styles['favorite']]: isJobFavorite,
    [styles['hiddenImage']]: hiddenImage,
  })

  const jobCardClass = classNames({
    [styles['job-card']]: true,
    [styles['fullBorder']]: fullBorder,
    [styles['mobileBorderTopAndBottomOnly']]: mobileBorderTopAndBottomOnly,
    [styles['multipleApplication']]: isMultiApplication,
  })

  const cardBlockClass = classNames({
    [styles['card-block']]: true,
    [styles['hasSingleLine']]: hasSingleLine,
  })

  const durationClass = classNames({
    [styles['duration']]: true,
    [styles['withoutJobType']]:
      !hasJobType && !leftBadgeArr.includes(tagDescription),
  })

  const cardItem = (
    <>
      <div
        ref={cardRef}
        className={cardClass}
        onClick={onJobSelectEvent}
        onAuxClick={onHandleOtherClick}
        onContextMenu={onHandleOtherClick}
      >
        <div className={styles['card-img-wrap']}>
          <img
            data-test="job-list-image"
            src={imageUrl}
            alt={companyName}
            width={60}
            height={60}
            className="card-img-top"
            loading="lazy"
          />
        </div>

        <div className={cardBlockClass}>
          <h3
            data-test="job-list-title"
            title={positionName}
            className={styles['card-title']}
          >
            {positionName}
          </h3>
          <p
            data-test="job-list-text"
            title={companyName}
            className={styles['card-text']}
          >
            {evaluationScore && (
              <em className={styles['score']} data-test="company-score">
                <i className="icon-star"></i>
                {evaluationScore}
              </em>
            )}
            {companyVerificationStateType === 7 && (
              <i
                data-test="company-verification-icon"
                className="icon-verified"
              ></i>
            )}{' '}
            {companyName}
          </p>
          <div className={styles['card-desc']}>
            {distance && distance !== '0 km' && (
              <em
                data-test="job-list-distance"
                className={styles['card-distance']}
              >
                {distance}{' '}
                {shortAddress && (
                  <div className={styles['dot']}>
                    <span className={styles['circle']}>●</span>
                  </div>
                )}
              </em>
            )}
            <em data-test="job-list-city" className={styles['card-city']}>
              {shortAddress}
            </em>
          </div>

          <div data-test="job-list-tag" className={styles['card-status']}>
            {hasJobType && getJobType(workType)?.title}
            {leftBadgeArr.includes(tagDescription) && (
              <>
                <div className={styles['dot']}>
                  <span className={styles['circle']}>●</span>
                </div>
                <span style={{ color: tagColor }}>{tagDescription}</span>
              </>
            )}
          </div>
        </div>

        {hasFavorite && (
          <>
            <span
              data-test="jobcardbasic2-favorite"
              className={styles['edit-favorite']}
              onClick={(e) => onFavoriteClick(e)}
              title={
                isJobFavorite ? 'Bu ilanı kaydedilenlere ekledin' : 'Kaydet'
              }
            >
              <i className={`${styles.icon} icon-bookmark`}></i>
              <i className={`${styles['icon-fill']} icon-bookmark-fill`}></i>
            </span>
          </>
        )}
      </div>
      <em data-test="job-list-duration" className={durationClass}>
        {rightBadgeArr.includes(tagDescription) ? (
          <div data-test="card-status" className={styles['card-status']}>
            <span className="badge-text" style={{ color: tagColor }}>
              {tagDescription}
            </span>
          </div>
        ) : (
          durationDayText
        )}
      </em>
    </>
  )

  return (
    <div className={jobCardClass}>
      {isMultiApplication ? (
        cardItem
      ) : (
        <a
          data-test="job-card-link"
          href={shareUrl}
          className={styles['card-wrap']}
          title={positionName + ' - ' + companyName}
        >
          {cardItem}
        </a>
      )}
    </div>
  )
})

export default JobCardBasic2
