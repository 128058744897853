import { memo, useState } from 'react'
import styles from './index.module.scss'
import { useGlobalContext } from 'contexts'

const HomeHeader2 = memo(({ onLoginDialogOpen }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen)
  const mainUrl2 = process.env.NEXT_PUBLIC_MAIN_URL2
  const mainUrl = process.env.NEXT_PUBLIC_MAIN_URL

  const { isLogin } = useGlobalContext()

  const links = [
    { label: 'İş İlanları', url: mainUrl2 + '/is-ilanlari' },
    { label: 'İşin Olsun Hizmet', url: mainUrl2 + '/hizmet' },
    { label: 'Çalışan Arıyorum', url: mainUrl2 + '/isveren' },
    { label: 'Blog', url: mainUrl + '/blog/' },
  ]

  const ProfilButton = () => {
    return (
      <a
        className={`${styles.btn} btn ${styles['btn-secondary']}`}
        href={`${mainUrl2}/profil`}
      >
        Profil
      </a>
    )
  }

  return (
    <nav className={`${styles['main-header']} navbar fixed-top`}>
      <div className="container">
        <div className={styles.leftSide}>
          <div
            data-test="hamburger-button"
            className={`${styles.hamburgerBtn} d-flex d-lg-none`}
            onClick={toggleMobileMenu}
          >
            {[1, 2, 3].map((_, index) => (
              <div key={index} className={styles.bar}></div>
            ))}
          </div>
          <a
            title="İşin Olsun"
            href={mainUrl2}
            className={`${styles['navbar-brand']}`}
          >
            İşin Olsun
          </a>
        </div>

        <div className={`${styles.rightSide} ${styles.links}`}>
          {links.map((link, index) => (
            <a key={index} className="d-none d-lg-block" href={link.url}>
              {link.label}
            </a>
          ))}
          {onLoginDialogOpen && (
            <>
              {isLogin && <ProfilButton />}
              {!isLogin && (
                <a className={`${styles.btn} btn`} onClick={onLoginDialogOpen}>
                  Giriş Yap / Üye Ol
                </a>
              )}
            </>
          )}
        </div>
      </div>

      <div
        data-test="overlay"
        className={`${styles.overlay} ${mobileMenuOpen ? styles.open : ''}`}
      >
        <div
          data-test="close-button"
          className={styles.closeBtn}
          onClick={toggleMobileMenu}
        >
          &times;
        </div>
        <div className={styles.menu}>
          {links.map((link, index) => (
            <a key={index} href={link.url}>
              {link.label}
            </a>
          ))}
        </div>
      </div>
    </nav>
  )
})

export default HomeHeader2
